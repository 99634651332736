import { Field, Label, Select, RadioGroup, RadioBar, RadioBarButton, Option } from '@troon/ui';
import dayjs from '@troon/dayjs';
import { Switch, Match, For, createMemo } from 'solid-js';
import { timeframes } from '../modules/date-formatting';
import type { DayFragment, Facility } from '../graphql';

type Props = {
	facility: Facility;
	selectedDay?: Date;
	selectedTime?: [number, number];
	selectedPlayers?: number;
};
export function TeeTimeAlertForm(props: Props) {
	const today = dayjs().tz(props.facility.timezone, true).startOf('day');

	const maxDate = createMemo(() => {
		return props.facility.courses.reduce((max, course) => {
			const day = course.bookingWindowDay as DayFragment;
			const date = dayjs.tz(`${day.year}-${day.month}-${day.day}`, props.facility.timezone).endOf('day');
			return max.isBefore(date) ? date : max;
		}, today);
	});

	return (
		<>
			<Switch>
				<Match when={props.facility.courses.length === 1 && props.facility.courses[0]}>
					{(course) => <input type="hidden" name="courseIds" value={course().id} />}
				</Match>
				<Match when>
					<Field name="courseIds">
						<Label suppressRequired>Course</Label>
						<Select>
							<Option value={props.facility.courses.map(({ id }) => id).join(',')} selected>
								All {props.facility.name} courses
							</Option>
							<optgroup label="Courses">
								<For each={props.facility.courses}>{(course) => <Option value={course.id}>{course.name}</Option>}</For>
							</optgroup>
						</Select>
					</Field>
				</Match>
			</Switch>

			<Field name="__date">
				<Label>Date</Label>
				<Select>
					<For each={new Array((maxDate()?.diff(today, 'days') ?? 13) + 1).fill(0).map((a, i) => i)}>
						{(i) => (
							<Option
								value={today.add(i, 'days').format('YYYY-MM-DD')}
								selected={dayjs(props.selectedDay).isSame(today.add(i, 'days'), 'day')}
							>
								{today.add(i, 'days').format('dddd, MMMM D')}
							</Option>
						)}
					</For>
				</Select>
			</Field>

			<Field name="__time">
				<Label>Time</Label>
				<Select>
					<For each={Object.entries(timeframes)}>
						{([label, [start, end]], i) => (
							<Option
								selected={
									props.selectedTime ? props.selectedTime[0] === start && props.selectedTime[1] === end : i() === 0
								}
							>
								{label}
							</Option>
						)}
					</For>
				</Select>
			</Field>

			<RadioGroup name="players">
				<Label>Players</Label>
				<RadioBar>
					<RadioBarButton checked={!props.selectedPlayers} value="-1">
						<Label>Any</Label>
					</RadioBarButton>
					<RadioBarButton value="1" checked={props.selectedPlayers === 1}>
						<Label>1</Label>
					</RadioBarButton>
					<RadioBarButton value="2" checked={props.selectedPlayers === 2}>
						<Label>2</Label>
					</RadioBarButton>
					<RadioBarButton value="3" checked={props.selectedPlayers === 3}>
						<Label>3</Label>
					</RadioBarButton>
					<RadioBarButton value="4" checked={props.selectedPlayers === 4}>
						<Label>4</Label>
					</RadioBarButton>
				</RadioBar>
			</RadioGroup>
		</>
	);
}
